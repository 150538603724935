<template>
  <el-container>
    <nav-main></nav-main>
    <el-main>
      <div class="bannerbox">
        <div class="topcenter">
          <div class="leftbox">
            <h2 class="tittleh2">豆豆记</h2>
            <p class="titp">
              豆豆记, 专业知识创作社区。致力于为个人或团队, 
              <br/>提供在线图书、专栏等的知识管理及创作,打造轻松简单的知识体系
            </p>
            <div class="btnbox">
              <el-link target="_blank" href="/down/win/beanotes_windows_v1.0.1.exe" >
                <el-button round size="medium" class="el-button">windows x64 v1.0.1</el-button>
              </el-link>
              <el-link target="_blank" href="/down/mac/beanotes_mac_v1.0.1.dmg" >
                <el-button round size="medium" class="el-button" >MacOS v1.0.1</el-button>
              </el-link>
              <el-link target="_blank" href="/down/linux/beanotes_1.0.1_amd64.deb">
                <el-button round size="medium" class="el-button">Ubuntu v1.0.1</el-button>
              </el-link>

            </div>
          </div>
          <div class="rightbox">
            <img src="../../assets/imgs/webimg/downimg.png" alt />
          </div>
        </div>
      </div>
      <div class="centerbox">
        <div class="leftbox">
          <img src="../../assets/imgs/webimg/downimg1.png" alt />
        </div>
        <div class="rightbox">
          <h2 class="tittleh2">团队协作</h2>
          <p class="titp">
            相同知识领域、相同爱好的成员
            <br />通过默契的协作，让彼此知识实现最大的价值
          </p>
        </div>
      </div>
      <div class="centerbox">
        <div class="leftbox">
          <h2 class="tittleh2">图书</h2>
          <p class="titp">
            熟悉的领域、专业的知识
            <br />通过图书，让知识大放异彩、服务大众
          </p>
        </div>
        <div class="rightbox">
          <img src="../../assets/imgs/webimg/downimg2.png" alt />
        </div>
      </div>
      <div class="centerbox">
        <div class="leftbox">
          <img src="../../assets/imgs/webimg/downimg3.png" alt />
        </div>
        <div class="rightbox">
          <h2 class="tittleh2">专栏</h2>
          <p class="titp">为一些在某个领域有深度研究的小伙伴提供写作专栏</p>
        </div>
      </div>
      <footer-all></footer-all>
    </el-main>
  </el-container>
</template>
<script>
import NavMain from "@/components/nav/navMain";
import FooterAll from "@/components/Footer/footerall";
export default {
  components: {
    NavMain,
    FooterAll,
  },
};
</script>
<style lang="less" scoped>
.el-container {
  flex-direction: column;
  height: 100%;
  .el-main {
    padding: 0;
  }
}
.topcenter {
  width: 1200px;
  margin: 0 auto;
  .leftbox {
    width: 600px;
    height: 400px;
    float: left;
    padding-left: 80px;
  }
}

.bannerbox {
  height: 455px;
  background: url("../../assets/imgs/webimg/downbanner.png") no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  box-sizing: border-box;
  .titp {
    line-height: 40px;
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .tittleh2 {
    box-sizing: border-box;
    margin: 50px auto 15px;
    font-size: 40px;
    color: #fff;
  }
}
.el-button {
  margin-right: 5px;
}
.centerbox {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding: 30px 0;
  box-sizing: border-box;
  justify-content: space-around;
  align-items: center;
}
.centerbox {
  .leftbox {
    margin-right: 100px;
  }
  .titp {
    line-height: 40px;
    color: #222;
    font-size: 20px;
    margin-bottom: 30px;
  }
  .tittleh2 {
    box-sizing: border-box;
    font-size: 30px;
    color: #222;
  }
}
</style>